import AboutMe from '../sections/AboutMe';
import SocialMedia from '../sections/SocialMedia';
import Contact from '../sections/Contact';
import Education from '../sections/Education';
import WorkExperience from '../sections/WorkExperience';
import Games from '../sections/Games';
import './Home.css';

function randomBackground() {
    const backgrounds = [
      "#973535",
      "#2678D1",
      "#17824F",
      "#000000",
      "#BA226B",
      "#660066",
    ];
    const index = Math.floor(Math.random() * backgrounds.length);
    return backgrounds[index];
}

function Home() {
    return ( 
        <div className="Home" style={{backgroundColor: randomBackground()}}>
            <div className="Home-body">
                <div>
                    <AboutMe/>
                    <hr/>
                    <WorkExperience/>
                    <hr/>
                    <Education/>
                    <hr/>
                    <Games/>
                    <hr/>
                    <Contact/>
                    <SocialMedia/>
                    <hr/>
                </div>
            </div>
            <footer>
                <div className="refresh-color">
                <a href="https://ivansanchez.dev">Refresh</a> for a surprise color
                </div>
            </footer>
        </div>
    ); 
} 
export default Home; 