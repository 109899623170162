import './PrivacyPolicy.css';
import { useLocation } from 'react-router-dom';

function PrivacyPolicy() {
  const location = useLocation();
  const language = new URLSearchParams(location.search).get('language');

  return (
    <div className="PrivacyPolicy" style={{ backgroundColor: "#FFFFFF" }}>
      <div className="PrivacyPolicy-body">
        {
          language ? (
            language === "es" ? spanishContent() : englishContent()
          ) : (
            englishContent()
          )
        }
      </div>
    </div>
  );
}

function englishContent() {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <p>
        Alejandro Cerda built the Skippy Dog game as
        a Commercial game. This SERVICE is provided by
        Alejandro Cerda  and is intended for
        use as is.
      </p>
      <p>
        This page is used to inform visitors regarding
        my policies with the collection, use, and
        disclosure of Personal Information if anyone decided to use
        my Service.
      </p>
      <p>
        If you choose to use my Service, then you agree
        to the collection and use of information in relation to this
        policy. The Personal Information that I collect is
        used for providing and improving the Service.
        I will not use or share your
        information with anyone except as described in this Privacy
        Policy.
      </p>
      <p>
        The terms used in this Privacy Policy have the same meanings
        as in our Terms and Conditions, which is accessible at
        Skippy Dog unless otherwise defined in this Privacy
        Policy.
      </p>
      <p><strong>Information Collection and Use</strong></p>
      <p>
        For a better experience, while using our Service,
        I may require you to provide us with certain
        personally identifiable information. The
        information that I request will be
        retained on your device and is not collected by me in any way.
      </p>
      <p>
        The game does use third party services that may collect
        information used to identify you.
      </p>
      <div>
        <p>
          Link to privacy policy of third party service providers
          used by the game
        </p>
        <ul>
          <li><a href="https://unity3d.com/legal/privacy-policy" target="_blank">Unity</a></li>
        </ul>
      </div>
      <p><strong>Log Data</strong></p>
      <p>
        I want to inform you that whenever
        you use my Service, in a case of an error in the
        game I collect data and information (through third
        party products) on your phone called Log Data. This Log Data
        may include information such as your device Internet
        Protocol (“IP”) address, device name, operating system
        version, the configuration of the game when utilizing
        my Service, the time and date of your use of the
        Service, and other statistics.
      </p>
      <p><strong>Cookies</strong></p>
      <p>
        Cookies are files with a small amount of data that are
        commonly used as anonymous unique identifiers. These are
        sent to your browser from the websites that you visit and
        are stored on your device's internal memory.
      </p>
      <p>
        This Service does not use these “cookies” explicitly.
        However, the game may use third party code and libraries that
        use “cookies” to collect information and improve their
        services. You have the option to either accept or refuse
        these cookies and know when a cookie is being sent to your
        device. If you choose to refuse our cookies, you may not be
        able to use some portions of this Service.
      </p>
      <p><strong>Service Providers</strong></p>
      <p>
        I may employ third-party companies
        and individuals due to the following reasons:
      </p>
      <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul> 
      <p>
        I want to inform users of this
        Service that these third parties have access to your
        Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated
        not to disclose or use the information for any other
        purpose.
      </p>
      <p><strong>Security</strong></p>
      <p>
        I value your trust in providing us
        your Personal Information, thus we are striving to use
        commercially acceptable means of protecting it. But remember
        that no method of transmission over the internet, or method
        of electronic storage is 100% secure and reliable, and
        I cannot guarantee its absolute security.
      </p>
      <p><strong>Links to Other Sites</strong></p>
      <p>
        This Service may contain links to other sites. If you click
        on a third-party link, you will be directed to that site.
        Note that these external sites are not operated by
        me. Therefore, I strongly advise you to
        review the Privacy Policy of these websites.
        I have no control over and assume no
        responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>
      <p><strong>Children's Privacy</strong></p>
      <p>
        These Services do not address anyone under the age of 13.
        I do not knowingly collect personally
        identifiable information from children under 13. In the case
        I discover that a child under 13 has provided
        me with personal information,
        I immediately delete this from our servers. If you
        are a parent or guardian and you are aware that your child
        has provided us with personal information, please contact
        me so that I will be able to do
        necessary actions.
      </p>
      <p><strong>Changes to This Privacy Policy</strong></p>
      <p>
        I may update our Privacy Policy from
        time to time. Thus, you are advised to review this page
        periodically for any changes. I will
        notify you of any changes by posting the new Privacy Policy
        on this page. These changes are effective immediately after
        they are posted on this page.
      </p>
      <p><strong>Contact Us</strong></p>
      <p>
        If you have any questions or suggestions about
        my Privacy Policy, do not hesitate to contact
        me at alejandro.cerda.h@gmail.com.
      </p>
    </div>
  );
}

function spanishContent() {
  return (
    <div>
      <h2>Política de privacidad</h2>
      <p>
        Alejandro Cerda creó el juego Skippy Dog como un juego comercial. Este SERVICIO es proporcionado por Alejandro Cerda y está destinado a ser utilizado tal como es.
      </p>
      <p>
        Esta página se utiliza para informar a los visitantes sobre mis políticas con la recopilación, el uso y la divulgación de información personal si alguien decide usar mi servicio.
      </p>
      <p>
        Si elige utilizar mi servicio, acepta la recopilación y el uso de información en relación con esto política. La información personal que recopilo es utilizada para proporcionar y mejorar el servicio. No utilizaré ni compartiré su información con nadie excepto como se describe en esta Política de Privacidad.
      </p>
      <p>
        Los términos utilizados en esta Política de privacidad tienen el mismo significado que en nuestros Términos y condiciones, a los que se puede acceder en Skippy Dog a menos que se definan de otra manera en esta Política de Privacidad.
      </p>
      <p><strong>Recopilación y uso de información</strong></p>
      <p>
        Para una mejor experiencia, mientras utiliza nuestro Servicio, puedo requerir que nos proporcione cierta información personalmente identificable. La información que solicito será retenida en su dispositivo y no la recojo de ninguna manera.
      </p>
      <p>
        El juego sí utiliza servicios de terceros que pueden recopilar información utilizada para identificarlo.
      </p>
      <div>
        <p>
          Enlace a la política de privacidad de los proveedores de servicios de terceros utilizados por el juego
        </p>
        <ul><li><a href="https://unity3d.com/legal/privacy-policy" target="_blank">Unity</a></li></ul>
      </div>
      <p>
        <strong>Datos de registro</strong>
      </p>
      <p>
        Quiero informarle que siempre que utilice mi Servicio, en caso de un error en el juego recojo datos e información (a través de productos de terceros) de su teléfono llamados Datos de registro. Estos Datos de registro pueden incluir información como la dirección de Internet Protocol (“IP”) del dispositivo, nombre del dispositivo, versión del sistema opertivo, la configuración del juego al utilizar mi Servicio, la hora y fecha de su uso del Servicio, y otras estadísticas.
      </p>
      <p>
        <strong>Cookies</strong>
      </p>
      <p>
        Las cookies son archivos con una pequeña cantidad de datos que son comúnmente utilizados como identificadores únicos anónimos. Estos son enviados a su navegador desde los sitios web que visita y se almacenan en la memoria interna de su dispositivo.
      </p>
      <p>
        Este Servicio no utiliza estas “cookies” explícitamente. Sin embargo, el juego puede utilizar código de terceros y bibliotecas que utilizan “cookies” para recopilar información y mejorar sus servicios. Tiene la opción de aceptar o rechazar estas cookies y saber cuándo se envía una cookie a su dispositivo. Si elige rechazar nuestras cookies, es posible que no pueda utilizar algunas partes de este Servicio.
      </p>
      <p>
        <strong>Proveedores de servicios</strong>
      </p>
      <p>
        Puedo emplear empresas de terceros e individuos debido a las siguientes razones:
      </p>
      <ul>
        <li>Para facilitar nuestro Servicio;</li>
        <li>Para proporcionar el Servicio en nuestro nombre;</li>
        <li>Para realizar servicios relacionados con el Servicio; o</li>
        <li>Para ayudarnos a analizar cómo se utiliza nuestro Servicio.</li>
      </ul>
      <p>
        Quiero informar a los usuarios de este Servicio que estos terceros tienen acceso a su Información Personal. La razón es para realizar las tareas asignadas a ellos en nuestro nombre. Sin embargo, están obligados a no divulgar ni utilizar la información para ningún otro propósito.
      </p>
      <p>
        <strong>Seguridad</strong>
      </p>
      <p>
        Valoro su confianza en proporcionarnos su información personal, por lo que nos esforzamos por utilizar medios comercialmente aceptables para protegerla. Pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro y confiable, y no puedo garantizar su seguridad absoluta.
      </p>
      <p>
        <strong>Enlaces a otros sitios</strong>
      </p>
      <p>
        Este servicio puede contener enlaces a otros sitios. Si hace clic en un enlace de un tercero, será dirigido a ese sitio. Tenga en cuenta que estos sitios externos no los opero yo. Por lo tanto, le recomiendo encarecidamente que revise la Política de privacidad de estos sitios web. No tengo control sobre ni asumo ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de ningún sitio o servicio de terceros.
      </p>
      <p>
        <strong>Privacidad de los niños</strong>
      </p>
      <p>
        Estos servicios no se dirigen a nadie menor de 13 años. No recolecto conscientemente información personal identificable de niños menores de 13 años. En el caso de que descubra que un niño menor de 13 años me ha proporcionado información personal, la elimino inmediatamente de nuestros servidores. Si usted es padre o tutor y sabe que su hijo nos ha proporcionado información personal, por favor contácteme para que pueda tomar las medidas necesarias.
      </p>
      <p>
        <strong>Cambios en esta política de privacidad</strong>
      </p>
      <p>
        Puedo actualizar nuestra política de privacidad de vez en cuando. Por lo tanto, se le recomienda revisar esta página periódicamente para ver si hay cambios. Le notificaré de cualquier cambio publicando la nueva política de privacidad en esta página. Estos cambios son efectivos inmediatamente después de que se publican en esta página.
      </p>
      <p>
        <strong>Contáctenos</strong>
      </p>
      <p>
        Si tiene alguna pregunta o sugerencia sobre mi política de privacidad, no dude en ponerse en contacto conmigo en alejandro.cerda.h@gmail.com.
      </p>
    </div>
  );
}

export default PrivacyPolicy;