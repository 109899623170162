import skippyThumbnail from '../images/skippy-thumbnail.png';
import './Games.css';

function Games() {
    return (
        <div>
            <h2 id="game-development">🕹️Game Development🕹️</h2>
            <p>
                Co-Creator & Programmer of SKIPPY DOG<br/>
                <a href="https://laresistancegames.itch.io/skippydog" target="_blank">
                    <img className='GameImage' style={{marginTop:"16px"}}
                        src={skippyThumbnail}
                    />
                </a>
            </p>
        </div>
    )
}

export default Games;