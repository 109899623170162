import avatar from '../images/avatar.jpg';
import './AboutMe.css';

function AboutMe() {
    return (
        <div>
            <img 
                src={avatar}
                className="Avatar"
                alt="avatar"
            />
            <h1 id="title">
                Ivan Sanchez Gutmann
            </h1>
            <p className='info'>
                Software Engineer 🧑‍💻 
                <br/>From Guadalajara, Mexico
                <br/>Currently in Seattle, US.
                <br/><br/>
                📖 Passionate about learning new technologies 
                🎮 Learning game development as a hobby <a href="https://laresistancegames.itch.io/" target="_blank">@LaResistanceGames</a> 
                &nbsp;🛠️ Eager to solve challenges by developing and improving software
            </p>
        </div>
    );
}

export default AboutMe;