import githubLogo from '../images/github.png';
import linkedinLogo from '../images/linkedin.png';
import './SocialMedia.css';

function SocialMedia() {
    return (
        <div>
            <a href="https://github.com/ivansanchezg" target="_blank">
                <img
                    src={githubLogo}
                    className="SocialMedia-logo"
                    alt="githubLogo"
                />
            </a>
            <a href="https://www.linkedin.com/in/ivansanchezg/" target="_blank">
                <img
                    src={linkedinLogo}
                    className="SocialMedia-logo"
                    alt="linkedinLogo"
                />
            </a>
        </div>
    );
}

export default SocialMedia;